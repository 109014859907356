import { createSlice } from '@reduxjs/toolkit';
import { PATH_DASHBOARD } from '../../routes/paths';
import Axios from '../../utils/axiosInstance';

const initialState = {
  LeaderList: [],
  FollowUpList: [],
  LeadSummary: [],
  LeadBoardList: [],
  FollowupBoardList: [],
  FollowupPendingList: [],
  ContactExistsList: [],
  leader: null,
  leaderId: null,
  leadervisit: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'leader',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    createLeader(state, action) {
      state.isLoading = false;
      state.leader = action.payload;
    },
    setLeaderId: (state, action) => {
      state.leaderId = action.payload;
    },
    getLeaderListSuccess(state, action) {
      state.isLoading = false;
      state.LeaderList = action.payload;
    },
    deleteLeader(state, action) {
      const updatedleaders = state.leader.filter((leader) => leader.id !== action.payload);
      state.leader = updatedleaders;
    },
    createLeaderVisit(state, action) {
      state.isLoading = false;
      state.leadervisit = action.payload;
    },
    createLeadFollowUp(state, action) {
      state.isLoading = false;
      state.leadfollowup = action.payload;
    },
    getLeadFollowUpByIdList(state, action) {
      state.isLoading = false;
      state.FollowUpList = action.payload;
    },
    getLeadSummarySuccess(state, action) {
      state.isLoading = false;
      state.LeadSummary = action.payload;
    },
    getLeadBoardListSuccess(state, action) {
      state.isLoading = false;
      state.LeadBoardList = action.payload;
    },
    getFollowUpBoardListSuccess(state, action) {
      state.isLoading = false;
      state.FollowupBoardList = action.payload;
    },
    getFollowUpPendingListSuccess(state, action) {
      state.isLoading = false;
      state.FollowupPendingList = action.payload;
    },
    getContactExistsListSuccess(state, action) {
      state.isLoading = false;
      state.ContactExistsList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { leadersList, deleteLeader, createLeader } = slice.actions;

// ----------------------------------  Create Leader ------------------------------------

export function dispatchCreateLeader(leader, navigate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/lead', leader);
      const { id, ...otherData } = response.data.data;

      dispatch(slice.actions.createLeader(otherData));
      dispatch(slice.actions.setLeaderId(id));

      navigate(`${PATH_DASHBOARD.lead.root}/${id}/view`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Leader ------------------------------------

export function dispatchUpdateLeader(leader) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/lead/${leader.id}`, leader);
      dispatch(slice.actions.createLeader(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Leader List-------------------------------------

export function getLeaderList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/lead');

      dispatch(slice.actions.getLeaderListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Leader delete-------------------------------------

export function dispatchDeleteLeader(leaderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await Axios.delete(`/lead/${leaderId}`);
      dispatch(slice.actions.deleteLeader(leaderId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------  Create Leader Visit------------------------------------

export function dispatchCreateLeaderVisit(leader) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/lead-visit', leader);
      return dispatch(slice.actions.createLeaderVisit(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------  Update Leader Visit ------------------------------------

export function dispatchUpdateLeaderVisit(leader) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/lead-visit/${leader.id}`, leader);
      dispatch(slice.actions.createLeaderVisit(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- Leader Followup Comments Create ---------------------------------------

export function dispatchNewLeadFollowUp(comments) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.post('/lead-followup', comments);
      dispatch(slice.actions.createLeadFollowUp(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------- Leader Followup Update ---------------------------------------

export function dispatchUpdateLeadFollowUp(comments) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.put(`/lead-followup/${comments.id}`, comments);
      dispatch(slice.actions.createLeadFollowUp(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------Leader Followup Comments Get visitId wise-------------------------------------

export function getLeadFollowUpById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/lead-followup/${id}`);
      dispatch(slice.actions.getLeadFollowUpByIdList(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------  All lead count  -------------------------------------

export function getLeadSummary() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/lead/lead-summary');
      dispatch(slice.actions.getLeadSummarySuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// -----------------------  Monthly lead-board -------------------------------------

export function getLeadBoardList(fromDate, toDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/lead/lead-board?from_date=${fromDate}&to_date=${toDate}`);
      dispatch(slice.actions.getLeadBoardListSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// -----------------------  Monthly followup-board -------------------------------------

export function getFollowUpBoardList(fromDate, toDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get(`/lead/followup-board?from_date=${fromDate}&to_date=${toDate}`);
      dispatch(slice.actions.getFollowUpBoardListSuccess(response.data.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// -----------------------  Monthly followup-pending -------------------------------------

export function getFollowUpPendingList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/lead/followup-pending');
      dispatch(slice.actions.getFollowUpPendingListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

// -----------------------  Monthly  -------------------------------------

export function checkContactExists(contact_no) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await Axios.get('/lead/check-contact', {
        params: { contact_no }
      });
      dispatch(slice.actions.getContactExistsListSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
