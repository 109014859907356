import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import orderReducer from './slices/order';
import storeReducer from './slices/store';
import userReducer from './slices/user';
import productReducer from './slices/product';
import dashboardReducer from './slices/dashboard';
import taskReducer from './slices/task';
import manufactureReducer from './slices/manufacture';
import costReducer from './slices/cost';
import inventoryReducer from './slices/inventory';
import productsReducer from './slices/products';
import calendarReducer from './slices/calendar';
import leaderReducer from './slices/leader';
import dataReportReducer from './slices/datareport';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  store: storeReducer,
  dashboard: dashboardReducer,
  task: taskReducer,
  product: productReducer,
  manufacture: manufactureReducer,
  cost: costReducer,
  inventory: inventoryReducer,
  products: productsReducer,
  calendar: calendarReducer,
  leader: leaderReducer,
  datareport: dataReportReducer
});

export { rootPersistConfig, rootReducer };
