import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useNavigationHistory() {
  const location = useLocation();
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setHistory((prevHistory) => {
      // Ensure only the latest 3 paths are kept to avoid unnecessary memory usage
      const newHistory = [...prevHistory, location.pathname].slice(-4);
      return newHistory;
    });
  }, [location.pathname]);

  const currentPath = history[history.length - 1] || null;
  const previousPath = history[history.length - 2] || null;
  const grandPreviousPath = history[history.length - 3] || null;
  const parentPreviousPath = history[history.length - 4] || null;


  return { currentPath, previousPath, grandPreviousPath, parentPreviousPath, history };
}
