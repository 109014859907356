import { createSlice } from '@reduxjs/toolkit';
import Axios from '../../utils/axiosInstance';

const initialState = {
  DataReportList: [],
  leader: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'datareport',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setLeaderId: (state, action) => {
      state.leaderId = action.payload;
    },
    getDataReportListSuccess(state, action) {
      state.isLoading = false;
      state.DataReportList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { leadersList, deleteLeader, createLeader } = slice.actions;

// -----------------------Leader List-------------------------------------

export function getDataReportList(fromDate, toDate, reportOptions) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const dataIds = reportOptions.join(',');
      const response = await Axios.get(
        `/data-reports/filter?from_date=${fromDate}&to_date=${toDate}&data_id=${dataIds || 1}`
      );

      dispatch(slice.actions.getDataReportListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

