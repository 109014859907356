import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useRef } from 'react';
import axios from '../utils/axiosInstance';
import { isValidToken, setSession } from '../utils/jwt';
import { AccessToken, UserId } from '../utils/strings';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const inactivityTimer = useRef(null);

  const startInactivityTimer = () => {
    clearInactivityTimer(); 

    inactivityTimer.current = setTimeout(() => {
      logout();
    }, 10 * 60 * 1000); 
  };

  const clearInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
  };

  useEffect(() => {
    const resetTimer = () => {
      startInactivityTimer(); // Reset inactivity timer on user interaction
    };

    // Listen for user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('mousedown', resetTimer);
    window.addEventListener('touchstart', resetTimer);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('mousedown', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
      clearInactivityTimer(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem(AccessToken);
        const userId = window.localStorage.getItem(UserId);

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`/users/${userId}`);
          const user = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
          startInactivityTimer();
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post('/auth/login', {
      username,
      password
    });

    const { user } = response.data;
    window.localStorage.setItem(UserId, user.id);
    setSession(response.data.access_token);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    startInactivityTimer();
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    clearInactivityTimer();
  };

  const updateProfile = () => {
    /*  */
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
