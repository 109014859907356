import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  product: getIcon('ic_products'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  invoice: getIcon('ic_invoice'),
  category: getIcon('ic_category'),
  report: getIcon('ic_report'),
  setting: getIcon('ic_settings'),
  store: getIcon('ic_stores2'),
  task: getIcon('ic_task'),
  salesreport: getIcon('ic_salesreport'),
  cost: getIcon('ic_cost'),
  manufacture: getIcon('ic_manufacture'),
  inventory: getIcon('ic_inventory'),
  designmaster: getIcon('ic_designmaster'),
  leaderboard: getIcon('ic_leaderboard'),
  master: getIcon('ic_master'),
  leader: getIcon('ic_leader'),
  datareport: getIcon('ic_datareport')
};

const getSidebarConfig = () => {
  const sidebarConfig = [];

  sidebarConfig.push(
    // MANAGEMENT
    {
      subheader: 'Management',
      items: [
        // DASHBOARD
        { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

        // INVOICE
        {
          title: 'Invoices',
          path: PATH_DASHBOARD.invoice.root,
          icon: ICONS.invoice
        },
        // TASK
        {
          title: 'Tasks',
          path: PATH_DASHBOARD.task.root,
          icon: ICONS.task
        },
        // MANUFACTURE
        {
          title: 'Manufacture',
          path: PATH_DASHBOARD.manufacture.root,
          icon: ICONS.manufacture,
          children: [
            { title: 'Order', path: PATH_DASHBOARD.manufacture.list },
            { title: 'Remake', path: PATH_DASHBOARD.manufacture.remakelist },
            { title: 'Resize', path: PATH_DASHBOARD.manufacture.resizelist },
            { title: 'Repair', path: PATH_DASHBOARD.manufacture.repairlist }
          ]
        },

        // LAB GROWN INVENTORY
        {
          title: 'Lab Grown Inventory',
          path: PATH_DASHBOARD.labgrown.root,
          icon: ICONS.inventory,
          children: [
            { title: 'Diamond', path: PATH_DASHBOARD.labgrown.diamond },
            { title: 'Gemstone', path: PATH_DASHBOARD.labgrown.gemstone },
            { title: 'Unset mount', path: PATH_DASHBOARD.labgrown.unmount },
            { title: 'Set mount', path: PATH_DASHBOARD.labgrown.setmount },
            { title: 'Earrings', path: PATH_DASHBOARD.labgrown.earrings },
            { title: 'Pendants', path: PATH_DASHBOARD.labgrown.pendants },
            { title: 'Bracelet', path: PATH_DASHBOARD.labgrown.bracelet }
          ]
        },

        // LAB GROWN INVENTORY
        {
          title: 'Natural Inventory',
          path: PATH_DASHBOARD.natural.root,
          icon: ICONS.inventory,
          children: [
            { title: 'Diamond', path: PATH_DASHBOARD.natural.diamond },
            { title: 'Gemstone', path: PATH_DASHBOARD.natural.gemstone },
            { title: 'Unset mount', path: PATH_DASHBOARD.natural.unmount },
            { title: 'Set mount', path: PATH_DASHBOARD.natural.setmount },
            { title: 'Earrings', path: PATH_DASHBOARD.natural.earrings },
            { title: 'Pendants', path: PATH_DASHBOARD.natural.pendants },
            { title: 'Bracelet', path: PATH_DASHBOARD.natural.bracelet }
          ]
        },
        // INVENTORY
        // {
        //   title: 'Inventory',
        //   path: PATH_DASHBOARD.inventory.root,
        //   icon: ICONS.inventory,
        //   children: [
        //     { title: 'Diamond', path: PATH_DASHBOARD.inventory.diamond },
        //     { title: 'Gemstone', path: PATH_DASHBOARD.inventory.gemstone },
        //     { title: 'Unset mount', path: PATH_DASHBOARD.inventory.unmount },
        //     { title: 'Set mount', path: PATH_DASHBOARD.inventory.setmount },
        //     { title: 'Earrings', path: PATH_DASHBOARD.inventory.earrings },
        //     { title: 'Pendants', path: PATH_DASHBOARD.inventory.pendants },
        //     { title: 'Bracelet', path: PATH_DASHBOARD.inventory.bracelet }
        //   ]
        // },

        // LEADER
        {
          title: 'Lead',
          path: PATH_DASHBOARD.lead.root,
          icon: ICONS.leader,
          children: [
            { title: 'Add Lead', path: PATH_DASHBOARD.lead.newLeader },
            { title: 'All Lead', path: PATH_DASHBOARD.lead.allLead },
            { title: 'Calendar', path: PATH_DASHBOARD.lead.leadcalender }
          ]
        },
        // PRODUCT
        // {
        //   title: 'Products',
        //   path: PATH_DASHBOARD.product.root,
        //   icon: ICONS.product
        // },
        // COST
        {
          title: 'Costs',
          path: PATH_DASHBOARD.cost.root,
          icon: ICONS.cost
        },
        // STORE
        // {
        //   title: 'Stores',
        //   path: PATH_DASHBOARD.store.root,
        //   icon: ICONS.store
        // },
        // USER
        // {
        //   title: 'Users',
        //   path: PATH_DASHBOARD.user.root,
        //   icon: ICONS.user
        // },
        // DESIGN DETAIL
        // {
        //   title: 'Design Master',
        //   path: PATH_DASHBOARD.designdetail.root,
        //   icon: ICONS.designmaster
        // },
        // MASTER
        {
          title: 'Master',
          path: PATH_DASHBOARD.master.root,
          icon: ICONS.master,
          children: [
            { title: 'Users', path: PATH_DASHBOARD.master.user },
            { title: 'Stores', path: PATH_DASHBOARD.master.store },
            { title: 'Design', path: PATH_DASHBOARD.master.design }
          ]
        },

        // REPORTS
        {
          title: 'Reports',
          path: PATH_DASHBOARD.report.root,
          icon: ICONS.report,
          children: [
            { title: 'Daily Reports', path: PATH_DASHBOARD.report.daily },
            {
              title: 'Monthly Reports',
              path: PATH_DASHBOARD.report.monthly
            },
            { title: 'Annual Reports', path: PATH_DASHBOARD.report.annual }
          ]
        },
        // SALES REPORTS
        {
          title: 'Leaderboard',
          path: PATH_DASHBOARD.salesreport.root,
          icon: ICONS.leaderboard,
          children: [
            { title: 'Daily Reports', path: PATH_DASHBOARD.salesreport.daily },
            {
              title: 'Monthly Reports',
              path: PATH_DASHBOARD.salesreport.monthly
            },
            { title: 'Annual Reports', path: PATH_DASHBOARD.salesreport.annual },
            { title: 'Calendar', path: PATH_DASHBOARD.salesreport.calendar }
          ]
        },
        // DATA REPORTS
        {
          title: 'Data Reports',
          path: PATH_DASHBOARD.datareport.root,
          icon: ICONS.datareport,
          children: [
            { title: 'Daily Reports', path: PATH_DASHBOARD.datareport.daily },
            {
              title: 'Monthly Reports',
              path: PATH_DASHBOARD.datareport.monthly
            },
            { title: 'Annual Reports', path: PATH_DASHBOARD.datareport.annual }
          ]
        }
      ]
    }
  );

  return sidebarConfig;
};

export default getSidebarConfig;
